@font-face {
  font-family: "Prompt";
  src: local("Prompt"),
    url("../assets/fonts/Prompt-Regular.ttf") format("truetype");
  font-weight: normal;
}

* {
  text-rendering: optimizeLegibility;
}

h4,
h2,
h3,
div {
  white-space: pre-line;
}

#root {
  white-space: pre-line;
}

li {
  list-style-type: none;
}
html {
  --wcm-z-index: 7000 !important;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif !important;
  font-size: 0.9rem;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

.analyticsIcon {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  object-fit: contain;
  margin-bottom: 10px;
}

.icon {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin-right: 10px;
  object-fit: contain;
}

.iconButton {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  object-fit: contain;
}

.chain {
  width: 44px;
  height: 44px;
  vertical-align: middle;
  margin-right: -10px;
  object-fit: contain;
}

.smIcon {
  width: 22px;
  height: 22px;
  margin: 2px;
  margin-right: 5px;
  vertical-align: middle;
  object-fit: contain;
}

.smSocialIcon {
  width: 22px;
  height: 22px;
  vertical-align: middle;
  margin-right: 20px;
  object-fit: contain;
}

.lgIcon {
  width: 57px;
  height: 57px;
  vertical-align: middle;
  object-fit: contain;
}

.block {
  margin-top: 60px;
}

.largeHeader {
  font-size: 48px;
  padding-top: 15px;
  font-weight: 600;
}

.header {
  font-size: 40px;
  font-weight: 700;
}

.subheader {
  margin-top: 180px;
  font-size: 40px;
  padding: 25px 0;
  font-weight: 700;
}

.description {
  color: #9b9c9d;
  font-size: 16px;
  font-weight: 500;
}

.card {
  background: white;
  color: #2c2c2c;
  border: 1px solid rgba(56, 56, 56, 0.5);
  box-shadow: 2.41px 2.41px 0.803333px rgba(0, 0, 0, 0.2);
  border: 1px solid #e6e6e6;
  text-align: left;
  font-size: 20;
  border-radius: 7px;
  margin: 10px 5px;
  padding: 10px;
}

.errorCard {
  border: 1px solid rgba(252, 85, 85, 0.7);
}

.tab {
  background: white;
  color: #2c2c2c;
  border: 1px solid rgba(56, 56, 56, 0.5);
  text-align: left;
  font-size: 14;
  border-radius: 10px;
  margin: 5px;
}

.valueCard {
  color: #383538;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  overflow: hidden;
  border-radius: 20px;
  margin: 10px;
  padding: 15px;
}

.projectCard {
  color: white;
  overflow: hidden;
  text-align: left;
  height: 167px;
  border-radius: 15px;
  margin: 10px;
  padding: 15px;
  font-weight: 600;
}

.alertCard {
  background-color: rgba(255, 255, 255, 0.3);
  color: black;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px;
  padding: 15px;
}

.cardHeader {
  color: white;
  font-size: 18px;
  padding-bottom: 15px;
  font-weight: 600;
}

.cardSubHeader {
  color: #2392ec;
  font-size: 16px;
  padding-bottom: 15px;
  font-weight: 700;
}

.cardTitle {
  font-weight: 700;
  font-size: 13px;
  color: #555555;
}

.cardValue {
  font-weight: 900;
  font-size: 17px;
  padding: 5px 0;
  color: #383538;
}

.cardLgValue {
  color: white;
  font-size: 36px;
  padding-bottom: 25px;
  font-weight: 700;
}

.tab {
  background-color: white;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  width: 100%;
}

.lightTab {
  background-color: rgba(225, 225, 225, 0.3);
  overflow: hidden;
  border-radius: 10px;
  margin: "0 10px";
  vertical-align: bottom;
  padding: 20px 20px;
}

.vaultHeader {
  text-align: left;
  font-size: 24px;
  vertical-align: middle;
  font-weight: 800;
  margin: 25px auto;
  max-width: 800px;
}

.vaultSubHeader {
  text-align: left;
  line-height: 1.2;
  font-size: 22px;
  font-weight: 500;
  margin: 25px auto;
  max-width: 800px;
}

.tableHeader {
  text-align: right;
  padding-top: 10px;
  font-size: 16px;
  font-weight: 700;
}

.tableLabel {
  font-size: 12px;
  color: #459bff;
  font-weight: 400;
}

.tableValue {
  font-size: 14px;
  font-weight: 700;
}

.dataTableHeader {
  font-size: 18px;
  font-weight: 700 !important;
  color: #0096ff;
}

.MuiDataGrid-columnHeaderTitle {
  font-size: 16px;
  font-weight: 700 !important;
  color: #0096ff;
}

.coloredTitle {
  color: #e84242;
  font-size: 20px;
  margin-top: 15px;
  font-weight: 600;
}

.coloredText {
  color: #e84242;
}

.dashboardHeader {
  font-size: 30px;
  vertical-align: middle;
  font-weight: 700;
}

.listHeader {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}

.listContent {
  text-align: right;
  font-size: 22px;
  font-weight: 600;
}

.valueHeader {
  font-size: 16px;
  line-height: 1.5;
  color: white;
  opacity: 0.6;
  font-weight: 600;
  margin-bottom: 15px;
}

.value {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
  color: white;
}

.price {
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.rightValue {
  font-size: 20px;
  font-weight: 600;
  color: white;
  text-align: right;
}

.tooltip {
  padding: 0;
  margin-left: 10px;
  margin-top: -2px;
}

.text {
  font-size: 16px;
  line-height: 1.5;
}

.labelLink {
  color: #383538;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
}

.cardTitleItem {
  width: 20%;
}

.w20 {
  width: 20%;
}

.w15 {
  width: 15%;
}

.w10 {
  width: 10%;
}

.w30 {
  width: 30%;
}

.w40 {
  width: 40%;
}

.rightText {
  float: right;
  font-weight: 600;
  margin-top: 20px;
}

.speedo-segment {
  margin: 10px;
  padding: 10px;
}

.InfiniteCarouselArrowIcon {
  border-color: black;
}

.MuiSelect-icon {
  margin-right: 10px;
  color: #383538 !important;
}

.custom-slideshow {
  width: 80rem;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.custom-slideshow .react-slideshow-wrapper {
  width: 70rem;
  margin-left: auto;
  margin-right: auto;
  overflow: visible;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
  background-color: white;
}

.MuiSlider-markLabel {
  color: #383538 !important;
}

.MuiRadio-root {
  color: #141314 !important;
}

.react-datetime-picker__wrapper {
  background-color: transparent;
  position: relative;
  border: 1px solid #e6e6e6;
  width: 100%;
  border-radius: 30px;
  padding: 5px 5px;
  margin: 5px 0px;
}

.react-datetime-picker__calendar {
  z-index: 1500;
}

.react-datetime-picker__inputGroup__input {
  color: #383538;
}

.react-datetime-picker__button__icon {
  stroke: #383538;
}

.PhoneInputInput {
  background-color: transparent;
  height: 50px;
  border-radius: 15px;
  border: 0px;
  color: #383538;
}

.PhoneInputInput {
  font-size: 18px;
  padding: 0 10px;
}

.drawerPaper {
  width: 250px;
  background: white;
  border: 1px solid #f0f0f0;
  box-shadow: 0 1px 5px 2px rgba(50, 50, 50, 0.1);
}

.MuiIconButton-root.Mui-disabled {
  color: "black";
}

.option {
  border-radius: 3px;
  margin-bottom: 10px;
  color: #2c2c2c;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: "#2B9BFF" !important;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalPaper {
  min-width: 300px;
  width: 100%;
  height: calc(100vh);
  overflow: auto;
}

.loadingPaper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

// .MuiList-root {
// }
.MuiMenuItem-root {
  font-size: 14px !important;
  font-family: "Inter", sans-serif !important;
}

.loginPaper {
  width: 80%;
  max-width: 527px;
  overflow: auto;
  text-align: center;
  background-color: white;
  border-radius: 20px;
}

.landingHeaderBg {
  background: linear-gradient(
    180deg,
    rgba(249, 130, 255, 0.15) 0%,
    rgba(139, 120, 255, 0.15) 40.9%,
    rgba(90, 143, 255, 0.15) 99.94%
  );
  box-shadow: 0px 2.12036px 10.6018px rgba(13, 21, 60, 0.2);
  border-radius: 10px;
  height: 600px;
  margin: 0;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.betweenRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aroundRow {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.startRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.endRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.item {
  margin: 10px 5px;
  padding: 6px 15px;
  font-weight: 700;
  font-size: 15px;
  color: #aaaaaa;
}

.selectedItem {
  background-color: #ffffff;
  color: #0096ff;

  border-radius: 3px;
}

.MuiIconButton-root {
  margin-right: 10px;
}

ul li.liwhite::before {
  content: "\2022";
  font-weight: bold;
  margin-right: 10px;
}

.highlight {
  font-size: 15px;
  font-weight: 900;
  margin: 3px;
  text-decoration-line: underline;
}

.cardDesc {
  font-weight: 700;
  font-size: 10px;
  text-align: left;
  padding: 5px 0px;
}

.cardTab {
  background-color: rgba(50, 160, 255, 0.25);
  border-radius: 4px;
}

.sc-launcher {
  z-index: 1000 !important;
  bottom: 70px !important;
}

.sc-open-icon {
  bottom: 70px !important;
}

.sc-closed-icon {
  bottom: 70px !important;
}

.tags-cell {
  overflow: auto !important;
}

.w20 {
  width: 20%;
}
.w15 {
  width: 15%;
}
.w10 {
  width: 10%;
}
.w35 {
  width: 35%;
}
.w40 {
  width: 40%;
}
